import _ from "lodash";
import { getTokenFromLocalStorage } from "../../../Services/LocalStorageService";

const EmailString = (emailArray) => {
    let stringMail = "";

    emailArray?.forEach((item) => {
        stringMail += item.trim() + ", "
    })
    let updateReportMail = stringMail.slice(0, -2);

    return updateReportMail;
}

const GetTimings = (Timings) => {
    let time = "";
    let AllTime = "";
    Timings?.hours?.forEach((hh) => {
        Timings?.minutes?.map((mm) => {
            const hour = String(hh).padStart(2, '0');
            const minute = String(mm).padStart(2, '0');
            time = `${hour}:${minute}`;

            const timeParts = time?.split(':');
            const hourNumber = parseInt(timeParts[0]);
            const minuteNumber = parseInt(timeParts[1]);

            let convertedTime;
            if (hourNumber === 0) {
                convertedTime = `12:${minuteNumber.toString().padStart(2, '0')} AM`;
            } else if (hourNumber < 12) {
                convertedTime = `${String(hourNumber).padStart(2,'0')}:${minuteNumber.toString().padStart(2, '0')} AM`;
            } else if (hourNumber === 12) {
                convertedTime = `12:${minuteNumber.toString().padStart(2, '0')} PM`;
            } else {
                convertedTime = `${(hourNumber - 12).toString().padStart(2, '0')}:${minuteNumber.toString().padStart(2, '0')} PM`;
            }
        
            AllTime += convertedTime + ", ";
            let times = AllTime?.split(",");
            const sortedArr = _.sortBy(times, (timeStr) => {
                const [hour, minute] = timeStr?.split(":").map(Number)
                return hour * 60 + minute
            })
            // console.log(sortedArr,"TTTTT")
            AllTime = sortedArr.join(",");
            return AllTime

        })
    })
    return AllTime;
}

const addOrdinalSuffix = (date) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const suffix = suffixes[(date - 20) % 10] || suffixes[date] || suffixes[0];
    return date + suffix;
}

const customMessage = (scheduler) => {
    let freq = scheduler?.frequency;
    let status = scheduler?.status;
    let reportText = "";
    if (scheduler !== undefined) {
        if(status === "Disabled"){
            reportText += `This report is disabled.`
            return reportText;
        }
        if (freq === "Day")
            reportText += `Every day at ${GetTimings(scheduler)}`;
        else if (freq === "Week") {
            reportText += "Every "
            scheduler?.days?.forEach((item) => {
                reportText += capitalizeFirstCharacter(item) + ", "
            })
            reportText = reportText.slice(0, -2);
            reportText += ` of the week at ${GetTimings(scheduler)}`;
        }
        else if (freq === "Month") {
            if (scheduler.interval === "1")
                reportText += `Every ${addOrdinalSuffix(scheduler.date)} of the month at ${GetTimings(scheduler)}`
            else
                reportText += `Every ${addOrdinalSuffix(scheduler.date)} of every ${scheduler.interval} month(s)  `
        }
        let updateReportText = reportText.slice(0, -2);
        return updateReportText;
    }
}

const GetDate=(number)=>{
// Create a new Date object
let date = new Date();

// Set the date components based on your number
date.setDate(number);
date.setMonth(date.getMonth());  // Note: January is 0 in JavaScript's Date object
date.setFullYear(date.getFullYear());
date.setHours(0,0,0,0)
return date;
}


const createPayloadEditor=(origin)=>{
    let originScheduler = {};
    originScheduler.frequency = capitalizeFirstCharacter(origin?.recurrence?.frequency);
    originScheduler.interval = origin?.recurrence?.interval;
    originScheduler.token = getTokenFromLocalStorage();
    originScheduler.scheduledJobName = origin?.scheduledJobName;
    originScheduler.description = origin?.description;
    originScheduler.SchedulerJobId = origin?.scheduledJobID;
    originScheduler.SchedulerJobGuid = origin?.pubLink?.split('/')[6];
    originScheduler.date = new Date(origin?.startDateTime).getDate().toString();
    if (origin?.recurrence?.frequency) {
        originScheduler.days = origin?.recurrence[`${origin?.recurrence?.frequency}`]?.days;
        originScheduler.hours = origin?.recurrence[`${origin?.recurrence?.frequency}`]?.hours;
        originScheduler.minutes = origin?.recurrence[`${origin?.recurrence?.frequency}`]?.minutes;
    }

    return originScheduler;
}

function capitalizeFirstCharacter(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export { customMessage, EmailString,GetDate ,capitalizeFirstCharacter,createPayloadEditor};