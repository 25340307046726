/* eslint-disable react-hooks/exhaustive-deps */
import { CAlert, CCard, CCardBody, CCardHeader, CCol, CFormText, CInput, CLabel, CModal, CModalBody, CModalHeader, CRow } from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { AquaSelectValues } from "../ScheduleSettingsJson";
import { getTokenFromLocalStorage, getUserDetailsFromLocalStorage, getZoneFromLocalStorage } from "../../../../Services/LocalStorageService";
import SaveCancelButton from "../../../../Components/SaveCancelButton";
import { customMessage } from "../../../reportMail/reportHelper/customMessage";
import ACreatableSelect from "../../../../Components/ACreatableSelect";
import { Authenticate } from "../../index";
import AReactSelect from "../../../../Components/AReactSelect";
import { LoggerContext } from "../../../WMS/Common_Helper/services/uiLoginServices";
import { v4 as uuidv4 } from 'uuid';
import AButton from "../../../../Components/AButton";
import createInterceptorInstance from "../../../../Services/Interceptor";
import handleErrorMessage, { HandleMessageFromResponse } from "../../../../helpers/handleErrorMessage";
import { getUserAccessPermission } from "../../../../Services/UserAccessPermission";

export const AquaScheduler = () => {
    const { logger } = useContext(LoggerContext);
    const history = useHistory();
    const location = useLocation();
    const current = `${location.pathname.split("/")[2]}`;
    const [sources, setSources] = useState([]);
    const [payload, setPayload] = useState({ Category: '', Source: '', Name: '', outputFormat: "CSV", publishLinkAccessScope: "public", scheduledJobName: "", description: "" });
    const [scheduleJob, setScheduleJob] = useState({ Category: '', Source: '', ViewName: '' });
    const [spinner, setSpinner] = useState({ Edit: false, save: false, saveBtn: true, validname: true });//for button spinner
    const [schedulerText, setSchedulerText] = useState("");//after scheduler display text
    const [jobHelp, setJobHelp] = useState(false);
    const [names, setNames] = useState([]);
    const [exceptionMessage, setExceptionMessage] = useState(null);
    const selectValues = AquaSelectValues;
    const userDetails = getUserDetailsFromLocalStorage();
    const clientAccount = userDetails?.LoginAccountCode;
    const [validationError, setValidationError] = useState('');
    const [jobCreateMsg, setJobCreateMsg] = useState({});
    const [message, setMessage] = useState("");
    const [validpayload, setValidPayload] = useState(true);
    const [changeFromInput, setChangeFromInput] = useState(false);
    const [isUserHasOnlyReadAccess,setIsUserHasOnlyReadAccess]=useState(true);

    const uniqueCategories = [...new Set(selectValues.map(item => item.Category))].map(category => {
        return { value: category, label: category };//For getting the unique categories
    });

    useEffect(() => {
        location?.pathname === "/aquascheduler/create" && Authenticate(); //for separate module

        if (location?.state) {
            setPayload({ ...payload, ...location?.state })
            if (location?.state?.Category) {
                setSources(getSources(location?.state?.Category));
                setScheduleJob({ ...scheduleJob, Category: location?.state?.Category });
            }
            if (location?.state?.Source) {
                getNames(location?.state?.Source);
            }
        }
        let isAccess=getUserAccessPermission("AIMS360OTSAJ01");
        setIsUserHasOnlyReadAccess(isAccess);
        setMessage(handleErrorMessage("AIMS360-Runway-AQUA-Schd-8001"))
    }, [])
    //Create the scheduler Text
    useEffect(() => {
        if (payload !== null) {
            setSchedulerText(customMessage(payload));
        }
    }, [payload])

    useEffect(() => {
        changeFromInput && setPayload({ ...payload, Source: "", Name: "" });
    }, [payload.Category])

    useEffect(()=>{
        isPayloadValid();
    },[payload,schedulerText])

    const isPayloadValid = () => {
        if (Object.keys(payload).length !== 0) {
            const { Category, Source, Name, outputFormat, publishLinkAccessScope, scheduledJobName } = payload;
            // Check if any field is empty
            if (
                Category?.trim() === '' || Source?.trim() === ''||Source === undefined || Name?.trim() === '' || Name === undefined || outputFormat?.trim() === '' ||
                publishLinkAccessScope?.trim() === '' || (scheduledJobName?.trim() === '' || scheduledJobName?.length < 8 || scheduledJobName?.length > 35) || schedulerText?.trim() === '') {
                setValidPayload(true);
                return true; // Payload is invalid
            } else {
                setValidPayload(false);
                return false; // Payload is valid
            }
        } else {
            return true;
        }

    };

    //After select one category get the source names
    const getSources = (value) => {
        const sources = selectValues
            .filter(item => item.Category === value)
            .map(item => item.Source);
        return [...new Set(sources)].map(source => {
            return { value: source, label: source };
        });
    }
    //After select one source get Names
    const getNames = (value) => {
        const values = selectValues.filter(obj => obj.Source === value);
        let OptionNames = values.map((item) => {
            return ({ "value": item.ViewName, "label": item.ViewName })
        })
        setNames(OptionNames);
    }
    //on Each input changes store the values
    const HandleInputChange = (event, name) => {
        if (name === 'Category') {
            setSources(getSources(event));
            setNames([]);
        }
        if (name === 'Source') {
            getNames(event);
        }
        let updatedPayload;
        if (name === "description") {
            updatedPayload = event.length > 8000
                ? { ...payload, [name]: event.slice(0, 8000) }
                : { ...payload, [name]: event };
        } else if (name === "Source") {
            updatedPayload = { ...payload, [name]: event, Name: "" };
        } else {
            updatedPayload = { ...payload, [name]: event };
        }
        
        setPayload(updatedPayload);
        
    }
    //Save the settings or schedulers 
    const CreateJob = () => {
        const guid = uuidv4();
        setSpinner({ ...spinner, save: true });
        let zone = getZoneFromLocalStorage(); let url = "";

        if (zone === "East") {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_EAST
        } else {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_WEST
        }
        payload.outputFormat = payload.outputFormat ? payload.outputFormat : "CSV";
        const URL = createInterceptorInstance(`${url}/AquaScheduler/CreateJob`); 
        URL.post('', payload,
            { headers: { "Authorization": `Bearer ${getTokenFromLocalStorage()}` } })
            .then((res) => {
                if (res.status === 200) {
                    setJobCreateMsg(res.data);
                    setSpinner({ ...spinner, save: false });
                    setPayload({})
                    setValidPayload(true);
                    location.state = "";
                } else {
                    setSpinner({ ...spinner, save: false });
                }
                logger('debug',guid, res, "AIMS360RunwayAquaScheduler", url,"POST",payload);
            }).catch((ex) => {
                logger('error',guid, ex, "AIMS360RunwayAquaScheduler", url,"POST",payload);
                console.log(ex);
                setSpinner({ ...spinner, save: false });
                handleError(ex);
            })
    }

    const handleError = (err) => {
        setExceptionMessage(err);
    }

    //validate the jobname
    const validate = (value, name) => {
        const symbolRegex = /^[A-Za-z0-9\-()._]+$/;

        if (value.length === 0) {
            setPayload({ ...payload, [name]: value });
            setValidationError('');
        } else if (value.length < 8 && !symbolRegex.test(value)) {
            setValidationError(handleErrorMessage("AIMS360-Runway-AQUA-Schd-8003"));
        } else if (value.length < 8) {
            setPayload({ ...payload, [name]: value });
            setValidationError(handleErrorMessage("AIMS360-Runway-AQUA-Schd-8002"));
        } else if (value.length > 35) {
            setValidationError(handleErrorMessage("AIMS360-Runway-AQUA-Schd-8002"));
        } else if (!symbolRegex.test(value)) {
            setValidationError(handleErrorMessage("AIMS360-Runway-AQUA-Schd-8004"));
        } else {
            setPayload({ ...payload, [name]: value })
            setValidationError('');
        }
    };

    //reset all the fields
    const reset = () => {
        setPayload({});
        setValidationError("");
        setValidPayload(true);
        location.state = "";
        localStorage.removeItem("originSettings");
    }

    //create http content from a sring which contain tags
    function createMarkup() {
        return (<div>
            <span dangerouslySetInnerHTML={{ __html: jobCreateMsg?.data }} />
        </div>)
    }

    return (
        <>
            <Helmet>
                <title>AIMS360 - Reports Aqua Scheduler</title>
            </Helmet>
            <CModal show={jobHelp}>
                <CModalHeader closeButton onClick={() => { setJobHelp(!jobHelp) }}><h5>Job Help</h5></CModalHeader>
                <CModalBody>
                    <p>
                        Public will have an authentication token embeded into the publishing link anyone can use this link to get the data.
                    </p>
                    <p>
                        Private caller will have to provide a bearer token to pickup the data as descirbed in api.AquaScheduler.io
                    </p>
                </CModalBody>
            </CModal>
            <CCard className={location?.pathname === "/aquascheduler/create" ? "vh-100 mb-0" : ""} style={{ overflow: "auto" }}>
                <CCardHeader>Aqua Scheduler</CCardHeader>
                <CCardBody style={{ height: "100%" }}>
                    <CRow className="justify-content-center py-2">
                        <CCol md="6">
                            <CRow className="">
                                <CCol md="4">
                                    <CLabel htmlFor="Category"><span className="text-danger">*</span>Aqua View Category</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-3">
                                    <AReactSelect
                                        menuPlacement={"auto"}
                                        value={payload?.Category ? { value: payload?.Category, label: payload?.Category } : {}}
                                        options={uniqueCategories}
                                        isClearable={payload?.Category}
                                        onChange={(e) => { HandleInputChange(e?.value, "Category"); setChangeFromInput(true) }}
                                        placeholder={"Search Country...."}
                                        isSearchable={true}
                                    />
                                </CCol>
                                <CCol md="4">
                                    <CLabel htmlFor="Source"><span className="text-danger">*</span>Aqua View Source</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-3">
                                    <AReactSelect
                                        menuPlacement={"auto"}
                                        value={payload?.Source ? { value: payload?.Source, label: payload?.Source } : {}}
                                        options={sources}
                                        isClearable={payload?.Source}
                                        onChange={(e) => HandleInputChange(e?.value, "Source")}
                                        placeholder={<small className='text-danger'>Select Warehouse</small>}
                                        isSearchable={true}
                                    />
                                </CCol>
                                <CCol md="4">
                                    <CLabel htmlFor="Name"><span className="text-danger">*</span>Aqua View Name</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-3">
                                    <ACreatableSelect
                                        menuPlacement={"auto"}
                                        value={payload?.Name ? { value: payload?.Name, label: payload?.Name } : {}}
                                        options={names}
                                        onChange={(e) => HandleInputChange(e?.value, "Name")}
                                        isClearable={payload?.Name}
                                        placeholder={<small className='text-danger'>Select Warehouse</small>}
                                        isSearchable={true}
                                    />
                                    <CFormText className="mb-0">{message}</CFormText>
                                </CCol>
                                <CCol md="4">
                                    <CLabel htmlFor="Type"><span className="text-danger">*</span>Export Type</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-3">
                                    <AReactSelect
                                        value={payload?.outputFormat ? { value: payload?.outputFormat, label: payload?.outputFormat } : { value: 'CSV', label: 'CSV' }}
                                        options={[{ value: 'JSON', label: 'JSON' }, { value: 'CSV', label: 'CSV' }]}
                                        onChange={(e) => { HandleInputChange(e?.value, "outputFormat") }}
                                    />
                                </CCol>
                                <CCol md="4" >
                                    <CLabel htmlFor="Settings"><span className="text-danger">*</span>Job Name</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-3">
                                    <div>
                                        <CInput type="text" name="scheduledJobName" autoComplete="off" value={payload?.scheduledJobName ? payload?.scheduledJobName : ""} placeholder="Enter The Job Name" className="" 
                                        onBlur={()=>{payload?.scheduledJobName?.length >= 8 || payload?.scheduledJobName === "" ? setValidationError(""):setValidationError(handleErrorMessage("AIMS360-Runway-AQUA-Schd-8002"))}} onChange={(e) => { validate(e.target.value, "scheduledJobName") }} />
                                        {validationError && <CFormText color="danger" className="m-0">{validationError}</CFormText>}
                                    </div>
                                </CCol>
                                <CCol md="4" className="">
                                    <CLabel htmlFor="Settings">Job Description</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-2">
                                    <textarea type="text" value={payload?.description ? payload?.description : ""} name="description" placeholder="Enter The Job Description" className="form-control" onChange={(e) => { HandleInputChange(e.target.value, e.target.name) }}></textarea>
                                </CCol>
                            </CRow>
                            <CCard className="mb-2">
                                <CCardBody className="px-2">
                                    <CRow>
                                        <CCol md="4" className="">
                                            <CLabel htmlFor="Settings"><span className="text-danger">*</span>Scheduler Settings</CLabel>
                                        </CCol>
                                        <CCol md="8">
                                            <CInput type="text" name="Settings" placeholder="Show the scheduler" className="" value={schedulerText} disabled />
                                        </CCol>
                                    </CRow>
                                    <div className="d-flex justify-content-end mt-2">
                                        <div className="text-right ">
                                            <AButton color="primary" disabled={validationError}
                                                onClick={() => {
                                                    history.push({
                                                        pathname: '/reports/aquascheduler/joblist/createjob/scheduler',
                                                        search: `?loginprefix=${clientAccount}`,
                                                        source: location?.pathname,
                                                        settings: payload,
                                                        state: current,
                                                        hideDisabled: true
                                                    })
                                                }}
                                                text="Edit"/>
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>
                            <div className="row justify-content-end">
                                <div className="col-md-5 d-block">
                                    <SaveCancelButton isReadOnlyPermission={isUserHasOnlyReadAccess} onSave={CreateJob} disable={validpayload} cancelDisable={spinner.save} spinner={spinner.save} onCancel={reset} />
                                </div>
                            </div>
                            {
                                exceptionMessage ?
                                <div className="mt-2">
                                    <HandleMessageFromResponse data={exceptionMessage}/>
                                </div> : ""
                            }
                        </CCol>
                    </CRow>
                    {
                        jobCreateMsg?.data &&
                        <CRow className="justify-content-center mt-5">
                            <CCol md="6">
                                <CAlert closeButton color="success" className={"overflow-auto Bg-crossIcon"} style={{ height: "300px" }}>
                                    {createMarkup()}
                                </CAlert>
                            </CCol>
                        </CRow>
                    }
                </CCardBody>
            </CCard>
        </>
    )
}